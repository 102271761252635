import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { fetchFromStorage, removeFromStorage, saveToStorage } from "../../storage";
import { identifiers } from "../../constants/identifiers";
import { URLS } from "../../constants/urls";
import { getBaseUrl, logoutUser } from "../../validators/HelperFunctions";
import { v4 as uuidv4 } from "uuid";

const axiosInstance = Axios.create({
	headers: { "Content-Type": "application/json" },
	// params: { lang: "en_IN" },
});
axiosInstance.defaults.timeout = 300000;

const refreshToken = () => {
	const body = new URLSearchParams();
	const token = fetchFromStorage(identifiers.refresh_token);

	body.append("refresh_token", token || "");
	body.append("scope", "webclient");
	body.append("grant_type", "refresh_token");
	const config = {
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		auth: {
			username: process.env.REACT_APP_CLIENT,
			password: process.env.REACT_APP_SECRET,
		},
	};

	Axios.post(URLS.login, body, config as any)
		.then(({ data, status }) => {
			if (status === 200) {
				removeFromStorage(identifiers.token);
				saveToStorage(identifiers.access_token, data.access_token);
				saveToStorage(identifiers.privilegeList, data.privileges);
				saveToStorage(identifiers.refresh_token, data.refresh_token);
				window.location.reload();
			}
		})
		.catch((error: any) => {
	
			logoutUser();
		});
};

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
	const token = fetchFromStorage(identifiers.access_token);
	const lang = fetchFromStorage(identifiers.selectedLanguage) ? JSON.parse(fetchFromStorage(identifiers.selectedLanguage) as any) : "";
	const clonedConfig: any = config;
	if (token) {
		clonedConfig.headers = {
			Authorization: `Bearer ${token}`,
			...clonedConfig.headers,
		};
	}

	if (clonedConfig.url.includes("https://api.beta.cloverbaylabs.com/")) {
		clonedConfig.headers = {
			"x-business-group-id": "ejje",
			"x-tenant-id": "ejje",
			"x-client-id": "webui",
			"x-trace-id": uuidv4(),
			"Content-Type": "application/json",
			...clonedConfig?.headers,
			Authorization:
				"Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJFTjU5Y1p6amxnZ1I2NHRScUhxNWVUUTNMWjViUkNPRTUxdkZGSTZkN1BnIn0.eyJleHAiOjE3MzI2MDMxODYsImlhdCI6MTcxNDQ1OTE4NiwianRpIjoiOWJhYTRjYmYtNDEwMy00NjY3LWE3MmUtOWNmNzhjYTY5NGExIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmJldGEuY2xvdmVyYmF5bGFicy5jb20vcmVhbG1zL2VqamUiLCJzdWIiOiJlMGRhMDVjNC04MmRkLTQ5MWYtYTU0ZC0yNTA4OTkzNTAwZDEiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJlamplIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwiZGVmYXVsdC1yb2xlcy1lamplIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX0sImVqamUiOnsicm9sZXMiOlsidW1hX3Byb3RlY3Rpb24iLCJsbnBfYWNjZXNzIl19fSwic2NvcGUiOiJwcm9maWxlX2luZm8iLCJjbGllbnRIb3N0IjoiNTQuMjA0LjYyLjIzMSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwicHJlZmVycmVkX3VzZXJuYW1lIjoic2VydmljZS1hY2NvdW50LWVqamUiLCJtZW1iZXJfb2YiOltdLCJjbGllbnRBZGRyZXNzIjoiNTQuMjA0LjYyLjIzMSIsImNsaWVudF9pZCI6ImVqamUifQ.Nrt6MS2iLzxIN6NnIUH1eWTfi2mRq3AYfq-jKnFVSOSEqBSZ-HC94i26hJNUXMHM_hE2bm8rJIa7fcIvn0YKJIM-lWEvc6fFsi4BHWclK6im2FuPN7HAHNaMJWTz5EC3uF01ViOOydEOjVPxSdpFyXOOD0YX3se_2Xgoo16kxx2LBprX1qXHQijisFGaarZFi_jLKWsk9c67YPqRm1VCkq0Fe_cjWRVpzTuTYfuu6Go3gH8sw4gThtYVPv0s79mpFJBfSGkXoVd7aMVdLgdxaSI_1rE3koSvLwNjRoZTLohrZcxEEsJJJoO9yj2hWWAtLC5C2HYUhqo7_o3zDi8_Eg",
		};
	}
	clonedConfig.params = {
		lang: lang ? lang.value : "es_CL",
	};
	return clonedConfig;
});

axiosInstance.interceptors.response.use(
	(config: AxiosResponse) => {
		return config;
	},
	(error: AxiosError) => {
		if (error.response && error.response.status === 401) {
			if (error.response.config.url !== URLS.login) {
				refreshToken();
			} else {
			}
			// window.location.href = "/";
		} else if (error.response && error.response.status === 503) {
			window.location.href = "/errorPage";
		} else {
			return Promise.reject(error);
		}
	}
);

export default axiosInstance;
